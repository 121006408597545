.st0 {
    fill: #726b9b;
    stroke: #47416a;
    stroke-width: 6;
    -webkit-transition: .15s fill ease-in-out;
    -o-transition: .15s fill ease-in-out;
    transition: .15s fill ease-in-out;
    cursor: pointer;
}

.st1 {
    fill: url(#a);
    stroke: #47416a;
    stroke-width: 6;
    -webkit-transition: .15s fill ease-in-out;
    -o-transition: .15s fill ease-in-out;
    transition: .15s fill ease-in-out;
    cursor: pointer;
}

.clicked {
    fill: #47416a !important;
    stroke-width: 6;
    -webkit-transition: .15s fill ease-in-out;
    -o-transition: .15s fill ease-in-out;
    transition: .15s fill ease-in-out;
}

.mantine-kalks6 {
    fill: #fff;
    width: 150%;
    height: 2.5rem;
    -webkit-filter: drop-shadow(0.625rem 0.3125rem 0.3125rem rgba(0, 0, 0, 0.03));
    filter: drop-shadow(0.625rem 0.3125rem 0.3125rem rgba(0, 0, 0, 0.03));
}

.mantine-1wfnqza {
    overflow: hidden;
    background-color: #fff;
}

.mantine-1f6zvz8 {
    fill: #f8f9fa;
    width: 220%;
    height: 2.625rem;
    -webkit-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
}